<template>
  <b-overlay :show="loading">
    <b-card class="w-full">
      <!-- Modal -->
      <b-modal class="w-full" v-model="showModal">
        <b-row>
          <b-col sm="12" md="6">
            <b-form-group label="Tanggal Ajuan">
              <b-form-input type="date" v-model="form.tanggal" />
            </b-form-group>
          </b-col>
          <b-col sm="12" md="6">
            <b-form-group label="Tanggal Berakhir">
              <b-form-input type="date" autofocus v-model="form.tgl_berakhir" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Nominal">
          <b-form-input v-model="form.nominal" @keyup="doFormatRupiah" />
        </b-form-group>
        <b-form-group label="Keterangan">
          <b-form-textarea v-model="form.keterangan" />
        </b-form-group>
        <template #modal-footer>
          <div class="d-flex justify-content-end">
            <b-button variant="info" @click.prevent="submit">{{
              isSales ? "Ajukan BOP" : "Simpan"
            }}</b-button>
          </div>
        </template>
      </b-modal>
      <!-- / -->
      <b-button variant="primary" @click.prevent="add" v-if="allowCreate()">
        Tambah Ajuan
      </b-button>
      <b-row>
        <b-col md="2" sm="4" class="my-1">
          <b-form-group class="mb-0">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="pageOptions"
              class="w-50"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" sm="8" class="my-1">
          <b-form-group
            label="Sort"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="sortBySelect"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
                class="w-75"
              >
                <template v-slot:first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
                class="w-25"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md="6" sm="12" class="my-1">
          <b-form-group
            label="Filter"
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- data -->
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            class="w-full "
            :per-page="perPage"
            :current-page="currentPage"
            :items="bops"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(nomor)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(sales)="{ item }">
              {{ item.sales ? item.sales.nama_lengkap : "-" }}
            </template>
            <template #cell(tanggal)="{ item }">
              {{ (item.tanggal && item.tgl_berakhir) ? `${humanDate(item.tanggal)} s/d ${humanDate(item.tgl_berakhir)}` : item.tanggal }}
            </template>
            <template #cell(nominal)="{ item }">
              Rp {{ formatRupiah(item.nominal) }}
            </template>
            <template #cell(status)="{ item }">
              <b-badge
                v-if="item.status && item.status.kode"
                variant="warning"
              >
                {{ item.status.ket }}
              </b-badge>
              <b-badge v-else variant="dark">Status tidak ditemukan</b-badge>
            </template>
            <template #cell(info)="{ item }">
              <b-badge :variant="getInfo(item).variant">
                <span>{{ getInfo(item).status }}</span>
              </b-badge>
            </template>
            <template #cell(actions)="{ item }">
              <div class="d-flex align-items-center">
                <b-button
                  v-b-tooltip.hover.right="'Detail'"
                  size="sm"
                  @click.prevent="$router.push(`/bop/detail/${item.id}`)"
                  variant="outline-success"
                >
                  <feather-icon icon="EyeIcon"></feather-icon>
                </b-button>
                <b-button
                  v-b-tooltip.hover.right="'Edit'"
                  v-if="allowUpdate() && getInfo(item).kode == 1"
                  size="sm"
                  @click.prevent="edit(item)"
                  variant="outline-info"
                >
                  <feather-icon icon="EditIcon"></feather-icon>
                </b-button>
                <b-button
                  v-b-tooltip.hover.right="'Hapus'"
                  v-if="allowDelete() && getInfo(item).kode == 1"
                  size="sm"
                  @click.prevent="remove(item)"
                  variant="outline-danger"
                >
                  <feather-icon icon="TrashIcon"></feather-icon>
                </b-button>
              </div>
            </template>
          </b-table>
        </b-col>
        <!-- / -->
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>
<script>
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BTable,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BOverlay,
  BFormCheckbox,
  BFormTextarea,
  BCard,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    ToastificationContent,
    VBTooltip,
    BModal,
    BFormTextarea,
    BDropdown,
    BBadge,
    BDropdownItem,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BOverlay,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormCheckbox,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      statusess: [],
      showModal: false,
      id: null,
      form: {
        sales_id: null,
        tanggal: new Date(),
        tgl_berakhir: null,
        nominal: 2500000,
        keterangan: null,
        status: null,
        appr_finance: 0,
        appr_kirim: 0, // approve spv itu teh
        appr_terima: 0,
      },
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "desc",
      loading: false,
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [],
      bops: [],
      params: { order: "desc" },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  async created() {
    this.loading = true
    this.form.tanggal = this.getCurrentDate()
    // if(this.form.nominal > 0 ) {
      this.form.nominal = this.formatRupiah(this.form.nominal)
    // }

    this.setFields()
    await this.getBOP();
    await this.getStatus();
    this.loading = false
  },
  methods: {
    doFormatRupiah() {
      this.form.nominal = this.formatRupiah(this.form.nominal)
    },
    setFields() {
      if(this.isSales) {
        this.fields = [
          {
            key: "nomor",
            label: "No",
          },
          {
            key: "no",
            label: "Nomor",
            sortable: true 
          },
          { key: "tanggal", label: "Tanggal", sortable: true },
          { key: "nominal", label: "Nominal", sortable: true },
          { key: "info", label: "Status", sortable: true },
          { key: "actions", label: "Aksi" }
        ]
      }
      else {
        this.fields = [
          {
            key: "nomor",
            label: "No",
          },
          {
            key: "no",
            label: "Nomor",
            sortable: true 
          },
          { key: "sales", label: "Nama Sales", sortable: true },
          { key: "tanggal", label: "Tanggal", sortable: true },
          { key: "nominal", label: "Nominal", sortable: true },
          { key: "info", label: "Status", sortable: true },
          { key: "actions", label: "Aksi" }
        ]
      }
    },
    async unduhbop(item) {
      this.loading = true
      const response = await this.$store.dispatch("bop/printAjuan", item.id);
      this.loading = false
      window.open(URL.createObjectURL(response))
    },
    getInfo(item) {
      const result = {
        variant: 'danger',
        status: 'Pending',
        kode: 1
      }

      if (item.appr_kirim == 0 && item.appr_finance == 0 && item.appr_terima == 0) {
        result.variant = 'danger'
        result.status = 'Pending'
        result.kode = 1
      } 
      else if (item.appr_kirim == 1 && item.appr_finance == 0 && item.appr_terima == 0) {
        result.variant = 'info'
        result.status = 'Disetujui SPV'
        result.kode = 2
      } 
      else if (item.appr_kirim == 1 && item.appr_finance == 1 && item.appr_terima == 0) {
        result.variant = 'warning'
        result.status = 'Disetujui Finance'
        result.kode = 3
      }
      else if (item.appr_kirim == 1 && item.appr_finance == 1 && item.appr_terima == 1) {
        result.variant = 'success'
        result.status = 'Selesai'
        result.kode = 4
      }
      else if (item.appr_kirim == 2 && item.appr_finance != 2) {
        result.variant = 'dark'
        result.status = 'Ditolak SPV'
        result.kode = 5
      }
      else if (item.appr_finance == 2) {
        result.variant = 'dark'
        result.status = 'Ditolak Finance'
        result.kode = 6
      }

      return result
    },
    add() {
      this.resetForm();
      this.showModal = true;
    },
    getStatusData(status_id) {
      const status = this.statusess.find((st) => st.data_status == status_id);
      return status;
    },
    async getStatus() {
      const currentMenu = await this.currentMenu();
      this.statusess = await this.$store.dispatch("statusrole/getData", {
        level_id: this.user.level.id,
        menu_id: currentMenu.id,
      });
      if (this.statusess.length > 0) {
        this.$store.commit("bop/SET_STATUS", this.statusess);
      }
    },
    async getBOP() {
      if (this.isSales) {
        this.params.sales_id = this.user.karyawan.id;
      }
      const bops = await this.$store.dispatch("bop/getData", this.params);
      bops.map(bop => bop.status = this.getInfo(bop)) // for canceled finance
      const pendingBop = bops.filter(bop => bop.status.kode == 1)
      const bossBop = bops.filter(bop => bop.status.kode == 1)
      const approvedSpvBop = bops.filter(bop => bop.status.kode == 2)
      const approvedFinanceBop = bops.filter(bop => bop.status.kode == 3)
      const completeBop = bops.filter(bop => bop.status.kode == 4)
      const cancelSpvBop = bops.filter(bop => bop.status.kode == 5)
      const cancelFinanceBop = bops.filter(bop => bop.status.kode == 6)

      let finalBops = [...pendingBop, ...completeBop, ...approvedSpvBop, ...approvedFinanceBop, ...cancelSpvBop, ...cancelFinanceBop]
      if(this.isSales) {
        finalBops = [...approvedFinanceBop, ...completeBop, ...pendingBop, ...approvedSpvBop, ...cancelSpvBop, ...cancelFinanceBop]
      }
      else if(this.isSPV) {
        finalBops = [...pendingBop, ...completeBop, ...approvedSpvBop, ...approvedFinanceBop, ...cancelSpvBop, ...cancelFinanceBop]
      }
      else if(this.isFinance) {
        finalBops = [...approvedSpvBop, ...pendingBop, ...completeBop, ...approvedFinanceBop, ...cancelSpvBop, ...cancelFinanceBop]
      }
      this.bops = finalBops;
      this.totalRows = finalBops.length;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Ajuan BOP ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("bop/save", [item])
            .then(() => {
              this.getBOP();
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
        }
      });
    },
    resetForm() {
      this.id = null;
      this.form = {
        sales_id: null,
        tanggal: this.getCurrentDate(),
        nominal: this.formatRupiah(2500000),
        keterangan: null,
        status: null,
        appr_finance: 0,
        appr_kirim: 0,
        appr_terima: 0,
      };
    },
    async submit() {
      if (!this.form.keterangan || !this.form.tanggal || !this.form.tgl_berakhir || !this.form.nominal) {
        this.displayError({
          message: "Harap lengkapi form dengan benar!",
        });
        return false;
      }
      
      const trimNominal = this.unFormatRupiah(this.form.nominal)
      let payload = this.form;
      if(payload.status && payload.status.kode) {
        payload.status = payload.status.kode
      }
      payload.nominal = trimNominal
      if (!payload.status) {
        const status = this.statusess.find((st) => st.data_status == 1);
        payload.status = 1;
      }
      if (this.id) [(payload.id = this.id)];

      this.loading = true;
      try {
        if (this.isSales) {
          payload.sales_id = this.user.karyawan.id;
        }
        if (this.isSPV) {
          payload.appr_kirim = 1;
        }
        const bop = await this.$store.dispatch("bop/save", [payload]);
        const key = 'ajuan_bop'
        const value = {
          level: this.user.level.id,
          data: bop
        }

        this.eventSocket(key, value)
        this.loading = false;
        this.displaySuccess({
          message: "Ajuan BOP Berhasil dikirim",
        });
        setTimeout(() => {
          this.showModal = false;
          this.resetForm();
          this.getBOP();
        }, 1000);
      } catch (e) {
        this.loading = false;
        this.displayError(e);
      }
    },
    edit(item) {
      this.id = item.id;
      this.form = item;
      this.form.nominal = this.formatRupiah(item.nominal)
      this.showModal = true;
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
